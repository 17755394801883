
























































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  DataDictionaryDtoPagedResultDto,
  FundDtoPagedResultDto,
  FundDto,
  JobDto, DataDictionaryDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import SelectCapitalExpenseRecordDetail from "@/views/finance/expenseRecord/selectBudgetItem.vue";

@Component({
  components: {
    SelectCapitalExpenseRecordDetail,
    AbSelect,
    Ueditor,
    SimpleUploadImage,
  },
})
export default class CreateJob extends Vue {
  @Ref() readonly dataForm!: ElForm;
  form: JobDto = {
    id: 0,
  };
  btnDisabled = false;
  salaryList:DataDictionaryDto[] = [];
  experienceList:DataDictionaryDto[] = [];
  educationList:DataDictionaryDto[] = [];

  get hostTypeFile() {
    return AttachmentHostType.Job;
  }

  async created() {
    await this.fetchDataDictionary();

    if (this.$route.params.id) {
      api.job.get({ id: Number(this.$route.params.id) }).then((res) => {
        this.form = { ...res };
      });
    }
  }


  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "Salary", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.salaryList = res.items!;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "Experience", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.experienceList = res.items!;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "Education", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.educationList = res.items!;
      });
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.job.update({
            body: this.form,
          });
        } else {
          await api.job.create({
            body: this.form,
          });
        }
        this.$router.back();
        this.$message.success("操作成功");
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    title: [
      {
        required: true,
        message: "标题必填",
        trigger: "blur",
      },
    ],
    recruitNumber: [
      {
        required: true,
        message: "招聘人数必填",
        trigger: "blur",
      },
    ],
    // salary: [
    //   {
    //     required: true,
    //     message: "薪资必填",
    //     trigger: "blur",
    //   },
    // ],
    contact: [
      {
        required: true,
        message: "联系方式必填",
        trigger: "blur",
      },
    ],
    city: [
      {
        required: true,
        message: "工作城市必填",
        trigger: "blur",
      },
    ],
    address: [
      {
        required: true,
        message: "地址必填",
        trigger: "blur",
      },
    ],
    // education: [
    //   {
    //     required: true,
    //     message: "学历条件必填",
    //     trigger: "blur",
    //   },
    // ],
    description: [
      {
        required: true,
        message: "详情必填",
        trigger: "blur",
      },
    ],
  };
}
